.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

html, body, #root {
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #253f66;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.nav, .nav:hover{
	text-decoration: none;
	color: #333;
	font-size: 17px;
	padding: 0 4px;
	margin: 0 12px;
}

button.nav{
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
}

input.form-control{
    padding: 14px 14px;
	border-radius: 14px !important;
}

input.form-control:hover{
	border-color: #000000de;
}

input.form-control:focus{
	border-color: #FFAF19;
	border-width: 2px;
	padding-top: 13px;
	padding-bottom: 13px;
	outline: 0 none;
	box-shadow: none;
}

.input-group .form-control {
	border-radius: 14px;
	margin-left: 12px !important;
}

input.form-control:read-only {
	background-color: #f5f5f5;
	color: #666;
}

button.dropdown-toggle{
    padding: 14px 14px;
	border-radius: 14px;
}

button.dropdown-toggle::after{
	top: 24px !important;
}

.rdtPicker td {
	padding: 1vw;
}

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
	background-color: #FFAF19 !important;
}

.rdtPicker td.rdtNew {
	color: #253f66 !important;
}

.rdtPicker td.rdtDisabled {
    color: #999 !important;
}

#autocomplete-wrapper{
	display: flex;
	flex: 1;
	align-items: center;
	background-color: #fff;
    padding: 6px 0;
	position: relative;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 14px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#autocomplete-wrapper:hover{
	border-color: #000000de;
}

#autocomplete-wrapper:focus-within{
	border-color: #FFAF19;
	border-width: 2px;
	padding-top: 5px;
	padding-bottom: 5px;
}

#autocomplete-wrapper .rbt-aux{
	position: absolute;
    top: 9px;
    right: 14px;
    opacity: .3;
}

.autocomplete-address-row{
	display: flex;
	flex: 1;
}

.autocomplete-address-row.hide{
	display: none;
}

.utility-select{
	margin-left: 12px !important;
	margin-top: 0 !important;
}

.utility-select .dropdown-toggle{
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 14px;
    padding: 15px 12px;
}

.utility-select .dropdown-toggle:hover{
	border-color: #000000de;
	color: #000;
}

.utility-select .dropdown-toggle:focus{
	border-color: #FFAF19;
	border-width: 2px;
	padding-top: 14px;
	padding-bottom: 14px;
}

#autocomplete-wrapper input{
	background-color: transparent;
	border: 0 none;
	padding: 0 16px;
	font-size: 16px;
}

#autocomplete-wrapper input:focus{
	box-shadow: none;
}

#autocomplete-wrapper>div{
	flex: 1;
}

#autocomplete-wrapper .dropdown-menu{
	top: 8px !important;
	width: 100% !important;
}

#autocomplete-wrapper .dropdown-item{
	padding: 8px 16px;
}

#autocomplete-wrapper .dropdown-item mark{
	background-color: #eff7fa;
	border-radius: 4px;
	padding: 0 4px;
}

.plan-saving{
	display: inline-block;
	margin: 6px auto 8px;
	font-size: 13px;
	font-weight: 500;
	border-bottom: 1.5px dashed #666;
	color: #666;
}

.plan-saving a{
	text-decoration: none;
	color: inherit;
}

.plan-type-options{
	position: relative;
	bottom: -1px;
}

.plan-type-options .nav-tabs{
	border-bottom: 0 none;
}

.form-control.is-valid, .was-validated .form-control:valid{
    border-color: #ced4da;
    background-image: none;
}

#agreement-wrapper{
	border: 1px solid #ddd;
	padding: 4px 24px;
    max-height: 700px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#agreement-wrapper table,#agreement-wrapper td{
	position: static;
}

.form-group{
	margin: 20px 0;
}

.page-wrapper{
	position: relative;
}

.page-wrapper>div{
	position: absolute;
	width: 100%;
}

#plans-wrapper{
	display: flex;
	padding-top: 20px;
}

#plans-list{
	padding: 24px 32px;
	flex: 1;
	overflow: hidden;
}

.plan-compare-btn, .plan-compare-btn:hover{
	background-color: #fff;
	border: 1px solid #ddd;
}

.plan-compare-btn.active{
	background-color: #FFAF19;
	color: #fff;
	border: 1px solid #FFAF19;
}

.plans-filter-options{
	display: flex;
	flex-wrap: wrap;
}

.plan-row-wrapper{
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 12px;
	margin: 20px 0;
	box-shadow: 0 3px 8px rgba(0, 0, 0, .03);
	overflow: hidden;
	transition: border-color .5s;
}

.plan-row-wrapper:hover{
	border-color: #999;
}

.plan-row{
	display: flex;
	padding: 0 16px;
}

.checkbox-item{
	display: flex;
	align-items: center;
	padding: 4px 12px;
	cursor: pointer;
	margin: 8px 0;
	flex: 0 0 50%;
	overflow: hidden;
}

.checkbox-item span{
	margin-left: 8px;
	color: #253f66;
	line-height: 1.1;
}

.checkbox-item span a{
	color: #666;
}

.checkbox-item.onlyread{
	opacity: .4;
	cursor: default;
}

.checkbox-item.full{
	flex: 0 0 100%;
}

.plans-filter-title{
	font-weight: 500;
	font-size: 17px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rep-logo{
	width: 80px;
	height: 42px;
}

.rep-logo i{
	display: inline-block;
	font-size: 11px;
	color: #999;
	font-style: normal;
	line-height: 1;
}

.rep-logo img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.plan-overview{
	display: flex;
	align-items: center;
}

.plan-info-group{
	display: flex;
	align-items: center;
	flex: 1;
}

.plan-info{
	flex: 1;
	text-align: center;
}

.plan-info>div{
	font-size: 18px;
	font-weight: 500;
	margin-bottom: -4px;
}

.plan-info label{
	font-size: 14px;
	color: #666;
	line-height: 1.2;
}

.plan-content{
	flex: 1;
	padding: 20px 0;
}

.plan-options{
	width: 200px;
	flex-shrink: 0;
	border-left: 1px solid #ddd;
	margin-right: -16px;
	margin-left: 16px;
    background: #F9FFFC;
	padding: 20px 0;
}

.plan-name{
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

.plan-desc{
	font-size: 13px;
	opacity: .7;
	margin: 0;
	line-height: 16px;
	max-height: 32px;
	position: relative;
	overflow: hidden;
}

.plan-desc.active {
	max-height: 999px;
}

.plan-desc-section a{
	font-size: 13px;
	padding: 2px 0;
	display: inline-block;
	color: inherit;
	opacity: .7;
	color: #26B7EA;
}

.plan-info .plan-est-rate{
	color: #FFAF19;
	font-size: 22px;
}

.plan-links a{
	display: block;
	margin-right: 24px;
	color: #253f66;
	opacity: .7;
}

.plan-detail{
	max-height: 0;
	overflow: hidden;
	padding: 0 20px;
	font-size: 15px;
	background-color: #fbfbfb;
}

.plan-detail.active{
	max-height: 9999px;
}

.mask{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .4);
}

.checkbox-row .checkbox-item{
	align-items: flex-start;
}

.checkbox-row .checkbox-item span{
	position: relative;
	top: -2px;
    margin-left: 12px;
}

.checkbox-item span.error{
	color: #dc3545;
}

.nav-link, .nav-link:focus, .nav-link:hover{
	color: #666;
}

.nav-tabs .nav-link.active{
	border-top: 2px solid #FFAF19;
}

.plans-filter-dd button{
	text-align: left;
	position: relative;
	padding-right: 32px;
}

.plans-filter-dd button::after{
	position: absolute;
	top: 16px;
	right: 10px;
}

.plans-filter-dd .dropdown-menu{
	width: 100%;
	max-height: 300px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.plans-filter-dd .dropdown-item{
	padding: 8px 16px;
	white-space: break-spaces;
}

.dropdown-item.active, .dropdown-item:active{
	background: #FFAF19;
}

.page-link, .page-link:hover{
	color: #666;
}

.page-item.active .page-link{
	color: #fff;
	background-color: #30D886;
	border-color: #30D886;
}

.modal-header button.close{
	background-color: transparent;
	border: 0 none;
	font-size: 26px;
	font-weight: 500;
	color: #999;
	z-index: 2;
}

.plan-tags span{
	font-size: 11px;
	font-weight: 500;
	color: #777;
	background-color: #f9f9f9;
	border-radius: 3px;
	display: inline-block;
	padding: 2px 6px;
	margin-right: 8px;
	margin-bottom: 4px;
    text-transform: uppercase;
}

.plan-detail-heading{
	font-size: 17px;
	margin-bottom: 8px;
	font-weight: 700;
}

.breakdown-table-wrapper{
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	margin-bottom: 24px;
	margin-left: -20px;
	margin-right: -20px;
	padding: 0 20px;
}

.breakdown-table-wrapper table{
	border: 1px solid #ddd;
}

.breakdown-table-wrapper td, .breakdown-table-wrapper th{
    text-align: center;
    padding: 12px 8px;
	font-size: 13px;
}

.breakdown-table-wrapper td.disabled, .breakdown-table-wrapper th.disabled{
    background-color: #f9f9f9;
    color: #ccc;
}

.breakdown-table-wrapper th{
	background-color: #f9f9f9;
}

.breakdown-table-wrapper td{
	background-color: #fff;
}

#plans-filter-toggle, #plans-filter-group .close-btn{
	display: none;
}

#plans-filter-group{
	margin-top: 32px;
}

.plans-filter{
	margin-bottom: 32px;
}

.get-usage-row{
	background-color: #f8f9fa;
	padding: 16px 20px;
	border-radius: 4px;
	border: 1px solid #ddd;
}

.drop-area{
	padding-top: 64px;
	padding-bottom: 64px;
	border: 2px dashed #ddd;
	border-radius: 12px;
	margin-bottom: 24px;
	cursor: pointer;
}

.drop-area.active{
	background-color: #E9F8F5;
	border-color: #30D886;
}

.list-group-item.active{
	background-color: #E9F8F5;
	border-color: #30D886;
	color: #30D886;
}

.list-group-item{
	padding-top: 12px;
	padding-bottom: 12px;
}

#term-filter-wrapper{
	margin-right: 12px;
}

#get-usage-option-wrapper{
	display: flex;
	flex-direction: column;
	margin-top: 12px;
}

.get-usage-option{
	cursor: pointer;
	border: 1px solid #ddd;
	border-radius: 12px;
	flex: 1;
	padding: 16px 20px;
	margin: 8px 0;
	display: flex;
	align-items: center;
	transition: border .3s;
	position: relative;
}

.get-usage-option h5{
	font-size: 17px;
}

.get-usage-option:hover{
	border-color: #999;
}

.get-usage-option p{
	margin-bottom: 0;
	font-size: 15px;
	line-height: 1.2;
}

.get-usage-option i{
	display: inline-block;
	font-style: normal;
	position: absolute;
	top: 3px;
	right: 3px;
	border-radius: 8px;
	padding: 1px 8px;
	font-size: 13px;
	font-weight: 600;
	background: linear-gradient(135deg, #17C2C4 0%, #30D886 100%);
	color: #fff;
}

.get-usage-option.highlight{
	border-color: #30D886;
	padding-top: 44px;
	padding-bottom: 44px;
	background-color: #F9FFFC;
}

.divider{
	text-align: center;
	border-bottom: 1px dotted #ccc;
	margin: 24px auto 36px;
	max-width: 540px;
}

.divider span{
	position: relative;
	font-size: 14px;
	top: 12px;
	color: #999;
	background-color: #fff;
	padding: 0 24px;
}

.container-md{
	max-width: 640px;
	margin: 44px auto 64px;
}

/* custom model */
.modal-header{
	border-bottom: 0 none;
	text-align: center;
	display: block;
}

.modal-title{
	margin-top: 32px;
	font-weight: 700;
}

.modal-header button.close{
	position: absolute;
	top: 16px;
	right: 20px;
	width: 32px;
	padding: 0;
}

.modal-content{
	border-radius: 12px;
    border: 0 none;
}

.modal-body{
	padding-left: 28px;
	padding-right: 28px;
}

/* fix react datepicker arrow position */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
	left: 50% !important;
    transform: translate3d(-50%, 0px, 0px) !important;
}

.react-datepicker__navigation{
	top: 8px !important;
}

.tooltip-icon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	box-sizing: content-box;
	color: #253f66;
	border: 1px solid #253f66;
	opacity: .5;
	border-radius: 50%;
	font-style: normal;
	font-size: 11px;
	margin-left: 4px;
	flex-shrink: 0;
}

.tooltip-theme{
	max-width: 220px;
	font-weight: normal;
	line-height: 1.5;
	font-size: 12px;
	border-radius: 8px !important;
	opacity: 1 !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

#watch-video-poster{
	padding: 1px;
}

.plan-options button{
	font-size: 15px;
	width: 80%;
}

.bar-cell .bar, .bar-cell .bar-top, .bar-cell:hover .bar{
	fill: #E0E6E8;
}

.bar-cell.special .bar, .bar-cell.special .bar-top, .bar-cell.special:hover .bar{
	fill: #00b7f1;
}

.bar-cell:hover .bar-top{
	fill: #5F5F5F;
}

.bar-cell.special:hover .bar-top{
	fill: #00b7f1;
}

.error .form-control, .error .dropdown button.dropdown-toggle{
	border-color: #f15536;
}

#plans-modal .modal-body {
    overflow-y: auto;
    height: 75vh;
	-webkit-overflow-scrolling: touch;
}

#utility-modal .modal-body {
    overflow-y: auto;
    min-height: 50vh;
	/* min-width: 43vh; */
	-webkit-overflow-scrolling: touch;
	padding-bottom: "0px";
}

@media (min-width: 1200px) {
	.modal-xl {
	  --bs-modal-width: 1200px;
	}
 }

.plan-count-badge {
	position: absolute;
	right: -12px;
	top: -10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #FFAF19;
	color: #fff;
	font-style: normal;
}

.no-data {
	padding-top: 50px;
	font-size: 15px;
	color: #666;
	text-align: center;
}

.autopaytyd1 {
	display: flex;
}

.autopaytyd11 {
	width: 47%;
}

.autopaytydtop{
	width: 80%;
}

.autopaytydtop2{
	/* font-size: "48px"; */
	width: 95%
}

.autopaytydtopin{
	font-size: 48px;
}

@media (max-width: 1170px) {
	.autocomplete-address-row{
		flex-direction: column;
	}

	.utility-select{
		margin-left: 0;
		margin-top: 12px;
		flex: 1;
	}

	.utility-select .dropdown-toggle, .utility-select .dropdown-menu{
		width: 100%;
	}

	.plan-overview{
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.plan-options{
		width: 180px;
	}

	.plan-info-group{
		width: 100%;
	}

	.plan-info-group .plan-info{
		text-align: left;
		padding-left: 4px;
	}
	
	.plan-intro{
		padding-left: 84px;
		margin-top: -54px;
	}
}

@media (max-width: 1280px) {
	.autopaytydtopin{
		font-size: 26px;
	}	
}

@media (max-width: 750px) {
	#plans-wrapper{
		flex-direction: column;
		padding-top: 0;
	}

	#plans-filter-toggle{
		display: inline-block;
		flex: 1;
	}

	#plans-sort-toggle{
		flex: 1;
	}

	#plans-sort-toggle .dropdown-toggle{
		width: 100%;
	}

	#plans-filter-group{
		display: none;
		margin-top: 0;
	}

	#plans-filter-group.show{
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 10;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding: 88px 20px 44px;
	}

	#plans-filter-group.show .close-btn{
		display: block;
	}

	#plans-list-header{
		flex-direction: column;
	}

	#term-filter-wrapper{
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}

	#term-filter-wrapper .plans-filter-options,
	#term-filter-wrapper .plans-filter-dd,
	#term-filter-wrapper .plans-filter-dd button{
		width: 100%;
	}
	
	#term-filter-wrapper .plans-filter-dd button{
		text-align: center;
	}

	.autopaytyd1 {
		display: flex;
		flex-direction: column;
	}

	.autopaytyd11 {
		width: 100%;
		margin-bottom: 30px;
	}

	.autopaytydtop{
		width: 100%;
	}

	.autopaytydtop2{
		/* font-size: "20px"; */
		width: 95%
	}

	.autopaytydtopin{
		font-size: 26px;
	}
}

@media (max-width: 560px) {
	#complete-btn {
		padding: 12px 4px;
	}
	
	#plans-list{
		padding-left: 12px;
		padding-right: 12px;
	}

	.plan-info-group{
		align-items: flex-start;
	}

	.plan-options{
		width: 130px;
	}

	.plan-options button{
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.rep-logo{
		width: 65px;
		height: 33px;
	}

	.rep-logo i{
		margin-top: 4px;
		font-size: 10px;
	}

	.plan-info>div{
		font-size: 16px;
	}

	.plan-info label{
		font-size: 13px;
		line-height: 1.1;
		margin-top: 6px;
	}

	.plan-options .plan-info label{
		padding: 0 12px;
	}

	.plan-info .plan-est-rate{
		font-size: 17px;
	}

	.plan-row-wrapper{
		margin-left: -4px;
		margin-right: -4px;
	}

	.plan-row{
		padding: 0 12px;
	}

	.plan-intro{
		padding-left: 75px;
	}

	.nav-tabs .nav-link{
		font-size: 14px;
	}

	.plan-provider-contact{
		font-size: 14px;
		margin-top: 12px;
	}

	.plan-info.plan-info-terms span{
		font-size: 12px;
	}

	.modal-body{
		padding-left: 16px;
		padding-right: 16px;
	}

	.modal-title{
		max-width: 80%;
		margin: 40px auto 0;
		line-height: 1.1;
	}

	#plans-modal .modal-body{
		height: 55vh;
	}

	.autopaytyd1 {
		display: flex;
		flex-direction: column;
	}

	.autopaytyd11 {
		width: 100%;
		margin-bottom: 30px;
	}

	.autopaytydtop{
		width: 100%;
	}

	.autopaytydtop2{
		/* font-size: "20px"; */
		width: 95%
	}

	.autopaytydtopin{
		font-size: 26px;
	}
}

.modal-backdrop {
    z-index: 999999999;
    width: 100%;
}

.modal {
    z-index: 999999999;
}

.nav-pills, .nav-pills:hover {
	display: flex;
	justify-content: center;
	margin-bottom: 12px;
}

.nav-pills .nav-item {
	border: 2px solid rgb(37,63,102);
	background-color: #fff;
	padding: 8px 24px;
	color: rgb(37,63,102);
}

.nav-pills .nav-item.active {
	background-color: rgb(37,63,102);
	color: #fff;
	font-weight: 500;
}

.nav-pills .nav-item:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.nav-pills .nav-item:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.nav-pills .nav-item i {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	font-size: 12px;
	margin-left: 4px;
	border-radius: 50%;
	background-color: #FFAF19;
	color: rgb(37,63,102);
	font-weight: 600;
	line-height: 1;
	font-style: normal;
	position: relative;
	top: -2px;
}

.hide-tabs .nav-pills {
	display: none;
}

.icg-es-concent {
	position: absolute;
	bottom: 190px;
	left: 44px;
	right: 24px;
	font-size: 12px;
	color: #666;
	background-color: #fafafa;
	padding: 8px;
	line-height: 1.1;
	border: 1px solid #eee;
	text-align: left;
	box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

#deposit-waiver-modal {
	font-size: 15px;
	line-height: 1.8;
}

#deposit-waiver-modal a {
	color: #253f66;
}

#deposit-waiver-modal li {
	list-style: disc;
	margin-left: 20px;
}

#deposit-waiver-modal b {
	font-size: 17px;
}

#enrollment-confirmation-page a {
	color: inherit;
}

#enrollment-confirmation-page a:hover {
	color: #f15536;
}
