.upload-bar {
    /* background-color: lightblue; */
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.upload-bar:hover{
    background-color: #E0EDFC;
}
.upload-bar .close-icon{
    visibility: hidden;
}
.upload-bar .close-icon{
    visibility: visible;
}

.file-name {
    margin: 0 10px;
}

.send-button {
    width: 100px;
    height: 40px;
    background-color: #254066;
    border-radius: 8px;
    margin-top: 20px;
    color: white;
}

.send-button2 {
    width: 100px;
    height: 50px;
    background-color: #254066;
    border-radius: 8px;
    margin-top: 20px;
    color: white;
}

.file-uploader{
    display: flex;
    flex-direction: column;
    align-items: center;
}
